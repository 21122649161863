import { ReactNode, lazy } from 'react';
import { ColumnConfig, tableViewMetadata } from '../../../models/TableView';
import { ActionTypeNames } from '../../form/ActionTypes';
import { FilterProps } from './FilterProps';

type MetaDataType = (typeof tableViewMetadata)[keyof typeof tableViewMetadata];
type Renderer = {
  render: (value: FilterProps) => ReactNode;
  renderOverView?: (columnConfig: ColumnConfig) => ReactNode;
};

export type FilterRendersKeys = MetaDataType | ActionTypeNames;

export const FilterRenderers: Record<FilterRendersKeys, Renderer> = {
  dueDate: {
    render: lazy(() => import('./DateFilter')),
    renderOverView: lazy(() => import('./overview/DateFilterOverview')),
  },
  members: {
    render: lazy(() => import('./MembersFilter')),
    renderOverView: lazy(() => import('./overview/MembersFilterOverview')),
  },
  progress: {
    render: lazy(() => import('./ProgressFilter')),
    renderOverView: lazy(() => import('./overview/ProgressFilterOverview')),
  },
  createdBy: {
    render: lazy(() => import('./MembersFilter')),
    renderOverView: lazy(() => import('./overview/MembersFilterOverview')),
  },
  lastModified: {
    render: lazy(() => import('./DateFilter')),
    renderOverView: lazy(() => import('./overview/DateFilterOverview')),
  },
  createdOn: {
    render: lazy(() => import('./DateFilter')),
    renderOverView: lazy(() => import('./overview/DateFilterOverview')),
  },
  effectiveDate: {
    render: lazy(() => import('./DateFilter')),
    renderOverView: lazy(() => import('./overview/DateFilterOverview')),
  },
  owner: {
    render: lazy(() => import('./MembersFilter')),
    renderOverView: lazy(() => import('./overview/MembersFilterOverview')),
  },
  editor: {
    render: lazy(() => import('./MembersFilter')),
    renderOverView: lazy(() => import('./overview/MembersFilterOverview')),
  },
  viewer: {
    render: lazy(() => import('./MembersFilter')),
    renderOverView: lazy(() => import('./overview/MembersFilterOverview')),
  },
  approver: {
    render: lazy(() => import('./MembersFilter')),
    renderOverView: lazy(() => import('./overview/MembersFilterOverview')),
  },
  reviewer: {
    render: lazy(() => import('./MembersFilter')),
    renderOverView: lazy(() => import('./overview/MembersFilterOverview')),
  },
  risk: {
    render: lazy(() => import('./RiskFilter')),
    renderOverView: lazy(() => import('./overview/RiskFilterOverview')),
  },
  status: {
    render: lazy(() => import('./StatusFilter')),
    renderOverView: lazy(() => import('./overview/StatusFilterOverview')),
  },
  allReferences: {
    render: lazy(() => import('./AllReferencesFilter')),
    renderOverView: lazy(() => import('./overview/AllReferencesFilterOverview')),
  },
  allAttachments: {
    render: lazy(() => import('./AllAttachmentsFilter')),
    renderOverView: lazy(() => import('./overview/AllAttachmentsFilterOverview')),
  },
  Address: {
    render: lazy(() => import('./GenericFilter')),
    renderOverView: lazy(() => import('./overview/GenericFilterOverview')),
  },
  DateAction: {
    render: lazy(() => import('./DateFilter')),
    renderOverView: lazy(() => import('./overview/DateFilterOverview')),
  },
  CurrencyInputAction: {
    render: lazy(() => import('./CurrencyFilter')),
    renderOverView: lazy(() => import('./overview/CurrencyFilterOverview')),
  },
  DocumentReferenceAction: {
    render: lazy(() => import('./ReferenceDocumentFilter')),
    renderOverView: lazy(() => import('./overview/ReferenceDocumentFilterOverview')),
  },
  EmailInputAction: {
    render: lazy(() => import('./GenericFilter')),
    renderOverView: lazy(() => import('./overview/GenericFilterOverview')),
  },
  FileOrLinkUploadAction: {
    render: lazy(() => import('./GenericFilter')),
    renderOverView: lazy(() => import('./overview/GenericFilterOverview')),
  },
  LinkAction: {
    render: lazy(() => import('./GenericFilter')),
    renderOverView: lazy(() => import('./overview/GenericFilterOverview')),
  },
  MultiFileUploadAction: {
    render: lazy(() => import('./GenericFilter')),
    renderOverView: lazy(() => import('./overview/GenericFilterOverview')),
  },
  NumberInputAction: {
    render: lazy(() => import('./GenericFilter')),
    renderOverView: lazy(() => import('./overview/GenericFilterOverview')),
  },
  PhoneNumberAction: {
    render: lazy(() => import('./GenericFilter')),
    renderOverView: lazy(() => import('./overview/GenericFilterOverview')),
  },
  PickListAction: {
    render: lazy(() => import('./GenericFilter')),
    renderOverView: lazy(() => import('./overview/GenericFilterOverview')),
  },
  ResourcePicklistAction: {
    render: lazy(() => import('./ResourcePicklistFilter')),
    renderOverView: lazy(() => import('./overview/ResourcePicklistFilterOverview')),
  },
  SingleFileUploadAction: {
    render: lazy(() => import('./GenericFilter')),
    renderOverView: lazy(() => import('./overview/GenericFilterOverview')),
  },
  SelectListAction: {
    render: lazy(() => import('./GenericFilter')),
    renderOverView: lazy(() => import('./overview/GenericFilterOverview')),
  },
  TextInputAction: {
    render: lazy(() => import('./GenericFilter')),
    renderOverView: lazy(() => import('./overview/GenericFilterOverview')),
  },
  TickboxAction: {
    render: lazy(() => import('./CheckboxFilter')),
    renderOverView: lazy(() => import('./overview/CheckboxFilterOverview')),
  },
  YesNoAction: {
    render: lazy(() => import('./YesNoFilter')),
    renderOverView: lazy(() => import('./overview/YesNoFilterOverview')),
  },
  template: {
    render: lazy(() => import('./TemplateFormFilter')),
    renderOverView: lazy(() => import('./overview/TemplateFormFilterOverview')),
  },
  // NOT IN USE
  subTitle: {
    render: () => null,
    renderOverView: () => null,
  },
  ViewAction: {
    render: () => null,
    renderOverView: () => null,
  },
  SectionDividerAction: {
    render: () => null,
    renderOverView: () => null,
  },
  PreSelectedForm: {
    render: () => null,
    renderOverView: () => null,
  },
  MultiSelectListAction: {
    render: () => null,
    renderOverView: () => null,
  },
  DescriptionAction: {
    render: () => null,
    renderOverView: () => null,
  },
  ChildFormListAction: {
    render: () => null,
    renderOverView: () => null,
  },
  AdHocFieldsAction: {
    render: () => null,
    renderOverView: () => null,
  },
  version: {
    render: () => null,
    renderOverView: () => null,
  },
  periodicNextReview: {
    render: () => null,
    renderOverView: () => null,
  },
};
