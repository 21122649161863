import { useTranslation } from 'react-i18next';
import Button, { ButtonType, ButtonSize } from '../shared/form-control/Button';
import NoFiltersSvg from '../../assets/images/empty-filters.svg';
import { useTableView } from '../../contexts/table-view/TableViewContext';
import { FC, createElement, memo, useCallback, useMemo } from 'react';
import { ColumnConfig, ColumnType, TableViewMetaDataKey, mainViewId } from '../../models/TableView';
import { FilterRenderers, FilterRendersKeys } from './filter-renderers/FilterRenderers';
import SkeletonLoader from '../shared/skeleton-loader/SkeletonLoader';
import XIcon from '../shared/icon/XIcon';
import TableViewColumnFilterMenu from './TableViewColumnFilterMenu';
import EditIcon from '../shared/icon/EditIcon';
import { useTableViewFilters } from '../../contexts/table-view/TableViewFilterContext';

const NoFilterRenderer: FC = () => {
  const { t } = useTranslation(['table-view']);
  return <div>{t('filter-menu.no-filters')}</div>;
};

const TableViewFiltersOverview = () => {
  const { t } = useTranslation(['table-view']);
  const { selectedTableView, selectedOriginalTableView, onResetFilters, onApplyFilter, selectedTemplateFormId } = useTableView();
  const { clearFilter, actionTypes } = useTableViewFilters();

  const columns = useMemo(() => {
    if (!selectedTemplateFormId || !selectedTableView?.columnConfigurations) return [];
    const selectedConfig = selectedTableView.columnConfigurations[selectedTemplateFormId];
    return (selectedConfig?.columns ?? []).filter((col) => col.filter);
  }, [selectedTemplateFormId, selectedTableView?.columnConfigurations]);

  const originalColumns = useMemo(() => {
    if (!selectedTemplateFormId || !selectedOriginalTableView?.columnConfigurations) return [];
    const configKey = selectedOriginalTableView.id === mainViewId ? mainViewId : selectedTemplateFormId;
    const selectedConfig = selectedOriginalTableView.columnConfigurations[configKey];
    return (selectedConfig?.columns ?? []).filter((col) => col.filter);
  }, [selectedTemplateFormId, selectedOriginalTableView.columnConfigurations, selectedOriginalTableView.id]);

  const renderer = useCallback(
    (columnConfig: ColumnConfig) => {
      if (columnConfig.type === ColumnType.MetaData) {
        return FilterRenderers[columnConfig.value as FilterRendersKeys]?.renderOverView;
      } else {
        const action = actionTypes[columnConfig.value];
        return FilterRenderers[action.actionType as FilterRendersKeys]?.renderOverView;
      }
    },
    [actionTypes],
  );

  const removeFilter = useCallback(
    (config: ColumnConfig) => {
      if (!selectedTemplateFormId) return;
      clearFilter(config, selectedTemplateFormId);
      onApplyFilter(config, undefined);
    },
    [clearFilter, onApplyFilter, selectedTemplateFormId],
  );

  const resetToDefaults = useCallback(() => {
    onResetFilters();
  }, [onResetFilters]);

  const canResetToDefaults = useMemo(() => {
    if (columns.length !== originalColumns.length) return true;

    return columns.some((column) => {
      const originalColumn = originalColumns.find((origCol) => origCol.value === column.value);
      return !originalColumn || originalColumn.filter !== column.filter;
    });
  }, [columns, originalColumns]);

  return (
    <div className="min-w-128 max-w-[800px] px-2">
      <div className="flex items-center justify-between">
        <h3 className="text-dpm-18 font-semibold">{t('table-view:global-actions.filters-popover.title')}</h3>
        <Button type={ButtonType.TERTIARY} size={ButtonSize.S} onClick={resetToDefaults} disabled={!canResetToDefaults}>
          {t('table-view:global-actions.filters-popover.reset')}
        </Button>
      </div>

      {columns.length === 0 ? (
        <div className="text-center">
          <img src={NoFiltersSvg} alt="No active filters" className="mx-auto p-8" />
          <p className="text-gray-2 pt-4">{t('table-view:global-actions.filters-popover.empty')}</p>
        </div>
      ) : (
        <div className="mt-4 max-h-[450px] overflow-y-auto pr-2">
          {columns.map((config, index) => (
            <div key={index} className="mb-4">
              <div className="flex items-center justify-between">
                <div className="text-dpm-14 mb-1 font-medium">
                  {config.type === ColumnType.MetaData ? t(`meta.${config.value as TableViewMetaDataKey}`) : actionTypes[config.value].title}
                </div>
                <div className="ml-4 flex items-center justify-end gap-2 pb-1">
                  {selectedTemplateFormId && (
                    <TableViewColumnFilterMenu
                      selectedTemplateId={selectedTemplateFormId}
                      columnConfig={config}
                      triggerIcon={<EditIcon className="h-5 w-5 flex-shrink-0" />}
                    />
                  )}

                  <XIcon className="h-5 w-5 flex-shrink-0" onClick={() => removeFilter(config)} />
                </div>
              </div>
              <SkeletonLoader ready type="rowDetail" rows={3}>
                {createElement(renderer(config) ?? memo(NoFilterRenderer), config)}
              </SkeletonLoader>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TableViewFiltersOverview;
