import { createContext, RefObject, useCallback, useContext, useEffect, useState } from 'react';
import { FCWithChildren } from '../../types/FCWithChildren';

type TableViewScrollContextProps = {
  onScrollLeft: () => void;
  onScrollRight: () => void;
  canScrollLeft: boolean;
  canScrollRight: boolean;
};

const TableViewScrollContext = createContext<TableViewScrollContextProps>(null!);

export const useTableViewScroll = () => useContext(TableViewScrollContext);

type TableViewScrollProviderProps = {
  columnWidths: number[];
  scrollContainerRef: RefObject<HTMLDivElement>;
};

export const TableViewScrollProvider: FCWithChildren<TableViewScrollProviderProps> = (props) => {
  const { children, columnWidths, scrollContainerRef } = props;
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [currentColumnIndex, setCurrentColumnIndex] = useState<number>(0);

  useEffect(() => {
    if (scrollContainerRef.current) {
      setCurrentColumnIndex(0);
      scrollContainerRef.current.scrollTo({ left: 0, behavior: 'smooth' });
    }
  }, [columnWidths, scrollContainerRef]);

  const onScrollLeft = () => {
    if (scrollContainerRef.current && currentColumnIndex > 0) {
      const newIndex = Math.max(currentColumnIndex - 1, 0);
      setCurrentColumnIndex(newIndex);
      scrollContainerRef.current.scrollTo({
        left: columnWidths.slice(0, newIndex).reduce((a, b) => a + b, 0),
        behavior: 'smooth',
      });
    }
  };

  const onScrollRight = () => {
    if (scrollContainerRef.current && currentColumnIndex < columnWidths.length - 1) {
      const newIndex = Math.min(currentColumnIndex + 1, columnWidths.length - 1);
      setCurrentColumnIndex(newIndex);
      scrollContainerRef.current.scrollTo({
        left: columnWidths.slice(0, newIndex).reduce((a, b) => a + b, 0),
        behavior: 'smooth',
      });
    }
  };

  const updateScrollLimits = useCallback(() => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
    }
  }, [scrollContainerRef]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      const scrollElement = scrollContainerRef.current;
      scrollElement.addEventListener('scroll', updateScrollLimits);
      updateScrollLimits();
      return () => {
        scrollElement.removeEventListener('scroll', updateScrollLimits);
      };
    }
  }, [scrollContainerRef, updateScrollLimits]);

  return (
    <TableViewScrollContext.Provider value={{ onScrollLeft, onScrollRight, canScrollLeft, canScrollRight }}>
      {children}
    </TableViewScrollContext.Provider>
  );
};
